export class ShowLayout {
  public static readonly type = '[ShowLayout] Show/Hide layout';
  constructor(public show: boolean) {}
}

export class ShowHeader {
  public static readonly type = '[ShowHeader] Show/Hide header';
  constructor(public show: boolean) {}
}

export class GetDictionaryValues {
  public static readonly type = '[GetDictionaryValues] Get dictionary values';
  constructor(public dictionaryId: number) {}
}

export class GetCategories {
  public static readonly type = '[CatalogTable] Get catalogs categories';
}

export class GetRecommendationCatalogItemsForUser {
  public static readonly type = '[CatalogTableItems] Get recommendation catalog items for user';
  constructor(public catalogId: number) {}
}

export class GetNoticesCount {
  public static readonly type = '[GetNoticesCount] Get Notices Count';
}

export class AllNotificationRead {
  public static readonly type = '[AllNotificationRead] All Notification Read';
}

export class GetUserApiKey {
  public static readonly type = '[Get] GetUserApiKey';
}

export class CreateUserApiKey {
  public static readonly type = '[Create] GetUserApiKey';
}

export class ClearUserApiKey {
  public static readonly type = '[Clear] ClearUserApiKey';
}

export class GetPopularApps {
  public static readonly type = '[GetPopular] Get popular apps';
}

export class GetVkNews {
  public static readonly type = '[GetVkNews] Get vk news';
}

export class GetUserDataProfiles {
  public static readonly type = '[Get] UserDataProfiles';
}

export class GetCatalogProviders {
  public static readonly type = '[CatalogTable] Get catalogs providers';
}
